import { useState } from 'react'
import MuiTooltip from '@mui/material/Tooltip'
import { grey } from '@mui/material/colors'

const colors = {
  white: {
    bgcolor: 'white',
    color: 'black',
    border: '1px solid black'
  },
  grey: {
    bgcolor: grey[900],
    color: grey[300],
    border: `1px solid ${grey[800]}`
  }
}

const Tooltip = ({ children, content, placement, color = 'white' }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const tooltipStyle = {
    tooltip: {
      sx: {
        opacity: 1,
        bgcolor: colors[color].bgcolor,
        color: colors[color].color,
        border: colors[color].border
      }
    }
  }

  return (
    <MuiTooltip
      title={content}
      placement={placement}
      componentsProps={tooltipStyle}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      <button className='cursor-pointer w-full' onClick={() => setShowTooltip(!showTooltip)}>
        {children}
      </button>
    </MuiTooltip>
  )
}

export default Tooltip
