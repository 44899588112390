import gql from 'graphql-tag'

export const UPDATE_NFT = gql`
  mutation adminUpdateNft(
    $id: String!
    $adminUpdateNftInput: AdminUpdateNftInput!
    $updateClaimInput: UpdateClaimInput
  ) {
    adminUpdateNft(id: $id, adminUpdateNftInput: $adminUpdateNftInput, updateClaimInput: $updateClaimInput) {
      id
      name
      serial
      status
      file
      image
      animation_url
      subType
      file_compressed
      file_thumbnail
      is_for_sale
      subType
      price
      floor_price
      reserved_price
      created_at
      description
      details
      end_sale
      unlockable_content
      unlockable_file
      review
      tags
      types
      categories
    }
  }
`

export const UPDATE_NFT_STATUS = gql`
  mutation statusNft($id: String!, $statusNftInput: StatusNftInput!) {
    statusNft(id: $id, statusNftInput: $statusNftInput) {
      id
      price
      auction_type
      status
      reserved_price
    }
  }
`

export const UPDATE_NFT_MINT_TRANSFER = gql`
  mutation mintTransferNft($id: String!, $mintTransferNftInput: MintTransferNftInput!) {
    mintTransferNft(id: $id, mintTransferNftInput: $mintTransferNftInput) {
      id
    }
  }
`

export const UPDATE_NFT_TYPES = gql`
  mutation adminUpdateNftTypes($id: String!, $adminUpdateNftTypesInput: AdminUpdateNftTypesInput!) {
    adminUpdateNftTypes(id: $id, adminUpdateNftTypesInput: $adminUpdateNftTypesInput) {
      id
      types
    }
  }
`
export const UPDATE_NFT_TAGS = gql`
  mutation adminUpdateNftTags($id: String!, $adminUpdateNftTagsInput: AdminUpdateNftTagsInput!) {
    adminUpdateNftTags(id: $id, adminUpdateNftTagsInput: $adminUpdateNftTagsInput) {
      id
      tags
    }
  }
`

export const UPDATE_NFT_CATEGORIES = gql`
  mutation adminUpdateNftCategories($id: String!, $adminUpdateNftCategoriesInput: AdminUpdateNftCategoriesInput!) {
    adminUpdateNftCategories(id: $id, adminUpdateNftCategoriesInput: $adminUpdateNftCategoriesInput) {
      id
      categories
    }
  }
`

export const CREATE_NFT = gql`
  mutation adminCreateNft($creatorId: String!, $createNftInput: CreateNftInput!, $createClaimInput: CreateClaimInput) {
    adminCreateNft(creatorId: $creatorId, createNftInput: $createNftInput, createClaimInput: $createClaimInput) {
      id
    }
  }
`

export const DELETE_NFT = gql`
  mutation deleteNft($id: String!) {
    deleteNft(id: $id) {
      id
    }
  }
`

export const EDIT_POK_AND_PLAY = gql`
  mutation editPokAndPlay($nft: String!, $update: UpdatePokAndPlayInput!) {
    updatePokAndPlay(id: $nft, pokAndPlayInput: $update) {
      pokAndPlay
      pokAndPlayPrice
    }
  }
`
