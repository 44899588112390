import { useEffect, useState } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import { ConnectionInput } from '..'

import { GET_NFTS, GET_NFT, CLAIM_NFT } from './queries'
import {
  UPDATE_NFT,
  UPDATE_NFT_TAGS,
  UPDATE_NFT_TYPES,
  UPDATE_NFT_CATEGORIES,
  DELETE_NFT,
  CREATE_NFT,
  UPDATE_NFT_MINT_TRANSFER,
  EDIT_POK_AND_PLAY
} from './mutations'

interface Filter {
  creator?: string
  owner?: string
  review?: string
}

export function useNfts(filter?: Filter, conn: ConnectionInput = {}) {
  const [cursor, setCursor] = useState(conn.after || '')
  const [nfts, setNfts] = useState([])

  useEffect(() => {
    setNfts([])
    setCursor('')
  }, [filter.review, filter.creator, filter.owner])

  const { data, fetchMore, error, ...rest } = useQuery(GET_NFTS, {
    variables: {
      filter,
      sort: { created_at: 'DESC' },
      conn: {
        ...conn,
        after: cursor
      }
    },
    fetchPolicy: 'no-cache'
  })

  if (error) console.error(error)

  useEffect(() => {
    const fetchedNfts = data?.adminNfts?.items || []
    const newNfts = [...nfts]
    fetchedNfts.forEach((fetched) => {
      if (!newNfts.find((nft) => nft.id === fetched.id)) newNfts.push(fetched)
    })
    setNfts(newNfts)
  }, [data?.adminNfts?.pageInfo?.endCursor])

  return {
    ...rest,
    nfts,
    hasMore: data?.adminNfts?.pageInfo?.hasNextPage,
    totalCount: data?.adminNfts?.totalCount,
    fetchMore: () => {
      setCursor(data?.adminNfts?.pageInfo?.endCursor)
    }
  }
}

export function useNft(id) {
  const { data, ...rest } = useQuery(GET_NFT, {
    variables: {
      id,
      conn: {}
    },
    fetchPolicy: 'no-cache'
  })

  const nft = data?.nft || {}

  return { ...rest, nft }
}

export async function fetchNfpsEdit(filter = {}, conn = {}, sort = {}) {
  const { data, error } = await useQuery(GET_NFTS, {
    variables: {
      filter,
      conn,
      sort
    },
    fetchPolicy: 'no-cache'
  })

  return {
    nfps: data?.nfts?.items || []
  }
}

export function useLazyUnlockableContent() {
  return useLazyQuery(CLAIM_NFT, {
    fetchPolicy: 'no-cache'
  })
}

export function useUpdateNft() {
  return useMutation(UPDATE_NFT, {
    awaitRefetchQueries: true,
    refetchQueries: ['nft']
  })
}

export function useUpdateMintTransferNft() {
  return useMutation(UPDATE_NFT_MINT_TRANSFER, {
    awaitRefetchQueries: true,
    refetchQueries: ['nft']
  })
}

export function useUpdateNftTypes() {
  return useMutation(UPDATE_NFT_TYPES, {
    awaitRefetchQueries: true,
    refetchQueries: ['nft']
  })
}

export function useUpdateNftTags() {
  return useMutation(UPDATE_NFT_TAGS, {
    awaitRefetchQueries: true,
    refetchQueries: ['nft']
  })
}

export function useUpdateNftCategories() {
  return useMutation(UPDATE_NFT_CATEGORIES, {
    awaitRefetchQueries: true,
    refetchQueries: ['nft']
  })
}

export function useCreateNft() {
  return useMutation(CREATE_NFT)
}

export function useDeleteNft() {
  return useMutation(DELETE_NFT)
}

export function useUpdatePokAndPlay() {
  return useMutation(EDIT_POK_AND_PLAY, {
    awaitRefetchQueries: true,
    refetchQueries: ['nft']
  })
}
