import { gql } from '@apollo/client'

export const GET_NFTS = gql`
  query adminNfts($conn: ConnectionInput!, $filter: FilterNftInput, $sort: SortNftInput) {
    adminNfts(conn: $conn, filter: $filter, sort: $sort) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items {
        id
        name
        explicit
        tags
        types
        categories
        serial
        status
        auction_type
        file
        image
        animation_url
        image
        is_for_sale
        subType
        file_playlist
        file_compressed
        file_thumbnail
        subType
        price
        reserved_price
        review
        token_id
        deleted
        owner {
          id
          name
        }
        creator {
          id
          wallet
          cloud_wallet
          name
          profile_picture
          cover_picture
          certified
          professional
          aml_verified
          creator_type
          createdNfts(conn: $conn) {
            totalCount
          }
        }
      }
    }
  }
`

export const GET_NFT = gql`
  query nft($id: String!, $conn: ConnectionInput!) {
    nft(id: $id) {
      id
      name
      serial
      status
      auction_type
      chain
      file
      image
      explicit
      tags
      types
      categories
      score
      animation_url
      image
      is_for_sale
      subType
      file_compressed
      file_thumbnail
      subType
      price
      reserved_price
      created_at
      moderated_at
      description
      details
      reason
      end_sale
      unlockable_content
      unlockable_file
      unlockable_description
      unlocked_url
      subType
      review
      token_id
      tx_hash
      contract
      royalties
      certificate
      agreed_terms
      comment
      reports(conn: $conn) {
        items {
          id
          reason
          accurate
          status
          created_at
          user {
            id
            name
          }
        }
      }
      pokAndPlay
      pokAndPlayPrice
      pokAndPlayConvert {
        progress
        status
        resolution
      }
      file_playlist
      file_duration
      deleted
      incomes {
        wallet
        percentage
      }
      hasClaimable
      claim {
        id
        validityDate
        description
        code
        message
        type
        status
        addresses {
          mail
          discord
          telegram
        }
      }
      purchases: bids(conn: {}, filter: { status: "awarded" }) {
        items {
          id
          amount
        }
      }
      owner {
        id
        wallet
        cloud_wallet
        name
        profile_picture
        cover_picture
        certified
        professional
        aml_verified
        creator_type
      }
      creator {
        id
        wallet
        cloud_wallet
        name
        profile_picture
        cover_picture
        certified
        professional
        aml_verified
        creator_type
        createdNfts(conn: $conn) {
          items {
            id
            name
            serial
            status
            auction_type
            file
            image
            animation_url
            image
            is_for_sale
            subType
            file_compressed
            file_thumbnail
            subType
            price
            reserved_price
            review
            token_id
            owner {
              id
              name
              name
              profile_picture
            }
            creator {
              id
              wallet
              cloud_wallet
              name
              profile_picture
            }
          }
          totalCount
        }
      }
    }
  }
`

export const CLAIM_NFT = gql`
  query claimNft($id: String!) {
    claimNft(id: $id) {
      id
      name
      description
      unlockable_content
      unlockable_file
      unlocked_url
      unlockable_description
    }
  }
`
